import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Crypto Risk Management`}</h1>
    <p>{`This is part 1 of what will be a 2 part series on how to manage risk in crypto. Part 1 will focus on those risks that are specific to crypto speculation only, then part 2 will provide an introduction to portfolio management theory from our forefathers in tradfi. `}</p>
    <h3>{`How Do You Mitigate Risk in Crypto?`}</h3>
    <p>{`As we all know - crypto is the wild west. Even those with a more skeptical attitude are regularly surprised by the speed, viciousness and magnitude of the blowups which occur on a regular basis (both to the downside and to the upside). While we will look at some techniques for mitigating the downside risks associated with crypto speculation, the key thing to remember is this - no model or checklist can adequately capture all the risks associated with speculating on even just one particular cryptocurrency, let alone a whole basket of them. `}</p>
    <p>{`If you learn one thing from this article, let it be this - that cryptocurrency speculation is a dangerous business, and while you may think you've covered everything, you must remember that there are always `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/There_are_known_knowns"
      }}>{`unknown unknowns`}</a>{`!`}</p>
    <h3>{`Crypto Risk Management Factors`}</h3>
    <p>{`Before we even think about what cryptocurrency we might choose to buy (or not), we need to have a realistic idea of the risks associated with ALL cryptocurrencies. `}</p>
    <h3>{`Private Key Loss/Theft`}</h3>
    <p>{`This is a massive source of risk that is ever-present in crypto, unlike any other are of speculation. The one thing that every single person to ever lose or expose their private key has in common is that they didn't think it would happen to them. Reading this right now, you probably don't believe it could happen to you. But how sure are you of that really? `}</p>
    <h3>{`Crypto Opsec`}</h3>
    <p>{`If the computer you use to access your crypto stopped working and none of the data was recoverable, could you recover your crypto? Have you practiced for this outcome such that you are 100% sure? What if your hardware wallet suddenly gave up the ghost? YOU ARE AT LEAST USING A HARDWARE WALLET AREN'T YOU!?`}</p>
    <p>{`What if someone got into your house while you weren't there and turned the place inside out looking for your seed phrase - are you 100% confident they COULDN'T find it?`}</p>
    <p>{`If a criminal had identified you as having significant crypto holdings and came to your door with a `}<a parentName="p" {...{
        "href": "https://bitcoinmagazine.com/culture/the-5-wrench-attack-and-your-bitcoin-stack"
      }}>{`wrench`}</a>{`, would you be able to defend yourself and your holdings?`}</p>
    <p>{`If a group of criminals engineered a `}<a parentName="p" {...{
        "href": "https://twitter.com/thomasg_eth/status/1492663192404779013"
      }}>{`coordinated social attack`}</a>{` for you specifically, would you be able to identify and avoid bogus permission requests?`}</p>
    <p>{`If the answer to any of these questions is less than an emphatic yes, don't even dream of putting any meaningful money into crypto. These are just the most obvious examples of how people are duped or forced into giving up the keys to the kingdom. For now your time is better spent educating yourself on `}<a parentName="p" {...{
        "href": "https://www.lopp.net/bitcoin-information/security.html"
      }}>{`basic opsec`}</a>{` and your money is considerably safer in tradfi.`}</p>
    <p>{`No one wants to hear this advice, as everyone in crypto has the feeling that they're late, and that they need to move fast to make up the ground they've lost on everyone else. You must be stronger than your monkey mind, hold yourself back and take the slow road -  this is the only way to mitigate the risk of a bottom 0-10% outcome, where you lose every cent you ever put into any crypto.`}</p>
    <h3>{`Smart Contract Risk`}</h3>
    <p>{`This topic gets a lot of publicity, and rightly so given the number of high profile hacks that continue to occur on a monthly basis. While reputable smart contract audit companies do help identify and stop bugs before contracts hit production, unfortunately at this stage there is no such thing as a sure thing.`}</p>
    <p>{`While the first wave of defi protocols (such as MakerDAO, Aave and Uniswap) have now been battle-tested over several years, nothing exactly qualifies as `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Lindy_effect"
      }}>{`Lindy`}</a>{` to the same extent as Bitcoin (`}<a parentName="p" {...{
        "href": "https://news.bitcoin.com/bitcoin-history-part-10-the-184-billion-btc-bug/"
      }}>{`allegedly`}</a>{`) does.`}</p>
    <p>{`Again, it is prudent to consider both known and unknown risks here - while your favourite project might been signed off by one or more of the top auditing firms, that can only provide partial assurances that the protocol will behave as expected. Every high-profile hack ever to have occurred came as a surprise to the token holders of the project. Remember - there are unscrupulous people in the world who would gladly fork a successful project, introduce some subtle, fatal flaw to the code, then execute a rug-pull and act surprised when the token crashes to zero. `}</p>
    <h3>{`Centralisation / 'Key Man' Risks`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://vitalik.ca/general/2021/04/07/sharding.html"
      }}>{`Blockchain Scalability Trilemma`}</a>{` posits that with 'simple' techniques, it is very hard to deliver a blockchain that is all three of:`}</p>
    <ol>
      <li parentName="ol">{`Scalable (high TPS, low cost)`}</li>
      <li parentName="ol">{`Secure (resistant to 51% attacks etc)`}</li>
      <li parentName="ol">{`Decentralised (not subject to the control of any one person/group/organisation)`}</li>
    </ol>
    <p>{`This last property is also an important consideration when thinking about a specific token - wherever there is a single point of failure, there is risk with regards to the price of that token. The `}<a parentName="p" {...{
        "href": "https://twitter.com/ADAMDAOofficial/status/1501247582017703941"
      }}>{`Andre Cronje`}</a>{` debacle illustrated this to devastating effect for proponents of the Fantom Ecosystem earlier this year. `}</p>
    <h3>{`Unknown Unknowns`}</h3>
    <p>{`While I've listed all the crypto-specific risk factors I can think of here, I want to return to what I said at the start of the article - these risks discussed are `}<em parentName="p">{`known`}</em>{`. We can take measures to minimise them, and that is of course the right thing to do. That said, minimising the risk is not the same as eliminating the risk. `}</p>
    <p>{`Like probably all of you, I believe that my opsec is good enough that I can sleep soundly at night. But it could be better. Perhaps I could raise the probability I never expose my private keys to anyone who shouldn't have it from 99.9% to 99.95% if I researched the matter more thoroughly and gave it my best effort. What I know for sure though, is no matter how good a process you put in place, the number can never reach 100%. `}</p>
    <p>{`Also... Did I mention there are unknown unknowns? Even if literally perfect opsec could exist, we generally only take care to defend ourselves from:`}</p>
    <ol>
      <li parentName="ol">{`Things we view as likely, a subset of..`}</li>
      <li parentName="ol">{`Things we view as possible, a subset of..`}</li>
      <li parentName="ol">{`Things we can imagine, a subset of..`}</li>
      <li parentName="ol">{`Things that could occur.`}</li>
    </ol>
    <p>{`What this means in practise is that, while we can and should mitigate the risks associated with holding crypto, we cannot ignore the fact that a total, catastrophic loss is always possible. We should therefore only speculate with money we can afford to lose, and not let our enthusiasm for a new, better financial system blind us to the possibility that we should be one of those unfortunate enough to lose it all.`}</p>
    <p>{`GL out there!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      